import React, {useEffect, useState} from 'react'
import axios from 'axios'
import './middle.css'

const Middle = () => {
  var [todoItems, setTodoItems] = useState()

  const getitems = async () => {
    await axios.get('http://localhost:1995/api/get-all-items').then((res) => {
        console.log(res)
        setTodoItems(res.data)
    }).catch((err) => {
        console.log(err)
    })
  }

  // Use Effect hook makes the function run when we load into the website
  useEffect(() => {
    getitems()
  }, [])


  const deleteitems = async (item__id) => {
    console.log(item__id)
    await axios.post('http://localhost:1995/api/delete-items', {
      _id: item__id
    }).then((res) => {
      console.log(res)
      window.location.reload()
    }).catch((err) => {
      console.log(err)
    })

  }

    
  return (
    <div className="middle">

        
        {
            todoItems?.map((item) => {
                return (
                    <div className="list-item">
                      <h1>
                          {item?.todoItem}
                      </h1>

                      <h2>
                        {item?.name}
                      </h2>


                      <button onClick={()=>deleteitems(item?._id)} >Delete</button>
                    </div>
                )
            })
        }

    </div>
  )
}

export default Middle