import React from 'react'
import './App.css';
import Header from './components/header';
import Middle from './components/middle';


function App() {
  return (
    <div className='app__container'>
        <Header />
        <Middle />
    </div>
  );
}

export default App;
