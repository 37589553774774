import React, {useState} from 'react'
import axios from 'axios'
import "./header.css"

const Header = () => {
  var [todoitem, setTodoitem]=useState('')

  var [name, setName]=useState('')

  const uplodeitems = async () => {
    console.log(todoitem)
    console.log(name)
    await axios.post('http://localhost:1995/api/post-todo', {
      todoitem, name
    }).then((res)=>{
      console.log(res)
      window.location.reload()
    }).catch((err)=>{
      console.log(err)
    })
  }

  return (
    <div className='heading_container'>
        <h1>
            Make A list!!!
        </h1>
        
        <div className='input_container' >
            <input value={todoitem} onChange={(e)=>setTodoitem(e.target.value)} type="text" placeholder='todo item'/>
            <input value={name} onChange={(e)=>setName(e.target.value)} type='text' placeholder='name'/>
            <button onClick={()=>uplodeitems()} >Add Item!</button>
        </div>
    </div>
  )
}

export default Header